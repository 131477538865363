// ==============================
// Override Variables
// 覆盖变量
// ==============================
$global-background-color-dark: #3b4252;
$header-background-color-dark: #292e39;
$global-font-color-dark: #eceff4;
$global-font-size: 16px;
$global-font-family: Monaco, PT Serif,Comic Code Ligatures, system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
$code-background-color-dark: #2e3440;
$code-color-dark: #699ec1;
$code-font-family: Comic Code Ligatures, Source Code Pro, Menlo, Consolas, Monaco, monospace, $global-font-family;

.header-title {
  display: inline-block;
  font-family: Monaco, Comic Code Ligatures!important;
  font-weight: bold;
  margin-right: 0.5rem;
  min-width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-flex: 10;
  flex: 10;
}

.cursor {
  display: inline-block;
  background: #d8dee9;
  line-height: 17px;
  margin-left: -2px;
  -webkit-animation: blink 1.2s infinite;
  width: 10px;
  height: 20px;
}

@-webkit-keyframes blink {
  0% {background: #292e39}
  50% {background: #d8dee9}
  100% {background: #292e39}
}

.code-title::after {
  padding-left: .2rem;
  font-family: Monaco, Comic Code Ligatures!important;
  font-size:14px!important;
  /*content: 'Code';*/
  //ontent: 'Commands'!important;  /*code block without language WAS marked as "code" */
}

$code-type-map: (
  // Custom code type
  language-bash: 'Bash',
  language-c: 'C',
  language-cs: 'C#',
  language-cpp: 'C++',
  language-clojure: 'Clojure',
  language-coffeescript: 'CoffeeScript',
  language-css: 'CSS',
  language-dart: 'Dart',
  language-diff: 'Diff',
  language-erlang: 'Erlang',
  language-go: 'Go',
  language-go-html-template: 'Go HTML Template',
  language-groovy: 'Groovy',
  language-haskell: 'Haskell',
  language-html: 'HTML',
  language-http: 'HTTP',
  language-xml: 'XML',
  language-java: 'Java',
  language-js: 'JavaScript',
  language-javascript: 'JavaScript',
  language-json: 'JSON',
  language-kotlin: 'Kotlin',
  language-latex: 'Router',
  language-less: 'Less',
  language-lisp: 'Lisp',
  language-lua: 'Lua',
  language-makefile: 'Makefile',
  language-markdown: 'Markdown',
  language-matlab: 'Matlab',
  language-objectivec: 'Objective-C',
  language-php: 'PHP',
  language-perl: 'Perl',
  language-python: 'Python',
  language-r: 'R',
  language-ruby: 'Ruby',
  language-rust: 'Rust',
  language-scala: 'Scala',
  language-scss: 'Scss',
  language-shell: 'Shell',
  language-sql: 'SQL',
  language-swift: 'Swift',
  language-tex: 'TeX',
  language-toml: 'TOML',
  language-ts: 'TypeScript',
  language-typescript: 'TypeScript',
  language-vue: 'Vue',
  language-yml: 'YAML',
  language-yaml: 'YAML',
);